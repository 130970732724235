/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import React from 'react';
import styled from '@emotion/styled';

import Layout from '../components/Layout';
import SEO from '../components/seo';
import Subheader from '../components/Subheader';
import { IS_DARK_MODE_KEY } from '../constants';
import { Toggle } from '../components/Toggle';

const Row = styled.div`
  margin-bottom: 3rem;
`;
const HelperText = styled.span`
  margin-left: 1rem;
`;
const RowHelper = styled.div`
  display: flex;
  align-items: center;
`;

interface Props {
  pageContext: {
    lastDeployed: string;
  };
}
interface State {
  isDarkMode: boolean;
}

class Settings extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isDarkMode: true, // default to dark mode!
    };
  }

  componentDidMount() {
    const body = document.getElementsByTagName('body')[0];
    const isDarkMode = body.getAttribute('data-theme') === 'dark';
    this.setState({
      isDarkMode,
    });
  }

  onChange = async event => {
    const body = document.getElementsByTagName('body')[0];
    const isOn = event.target.checked;
    if (isOn) {
      localStorage.setItem(IS_DARK_MODE_KEY, 'true');
      body.setAttribute('data-theme', 'dark');
      this.setState({
        isDarkMode: true,
      });
    } else {
      localStorage.setItem(IS_DARK_MODE_KEY, 'false');
      body.setAttribute('data-theme', 'classic');
      this.setState({
        isDarkMode: false,
      });
    }
  };

  render() {
    return (
      <Layout>
        <SEO title="Settings" />
        <Subheader crumbs={[]} />
        <h1>Settings</h1>

        <Row>
          <p>Last deployed:</p>
          <p>
            <i>{this.props.pageContext.lastDeployed}</i>
          </p>
        </Row>
        <Row>
          <p>Dark Mode</p>
          <RowHelper>
            <Toggle onChange={this.onChange} checked={this.state.isDarkMode} />
            <HelperText>{this.state.isDarkMode ? 'ON' : 'OFF'}</HelperText>
          </RowHelper>
        </Row>
      </Layout>
    );
  }
}

export default Settings;
