import React from 'react';
import './toggle.css';

interface Props {
  onChange: (event: any) => Promise<void>;
  checked: boolean;
}

export const Toggle = (props: Props) => {
  const { onChange, checked } = props;
  return (
    <div>
      <input id="cb1" className="tgl-input" type="checkbox" checked={checked} onChange={onChange} />
      <label className="tgl-label" htmlFor="cb1" />
    </div>
  );
};
